function openChatIfActive() {
    if(Tawk_API.isVisitorEngaged() == true) {
        Tawk_API.maximize();
        $('button#toggleChatButton').removeClass('hidden');
    }
}

function toggleChat() {
    Tawk_API.toggle();
}

function showStatusonline() {
    $('button#toggleChatButton').removeClass('hidden').addClass('animated bounceInUp').one('animationend webkitAnimationEnd MSAnimationEnd oAnimationEnd', function(e) {
        $(this).removeClass('animated bounceInUp')
    });
}

var Tawk_API = Tawk_API || {},
    Tawk_LoadStart = new Date();
    (function() {
        var s1 = document.createElement("script"),
        s0 = document.getElementsByTagName("script")[0];
        s1.async = true;
        s1.src = 'https://embed.tawk.to/56712c4d55d2ecaf170bca4b/default';
        s1.charset = 'UTF-8';
        s1.setAttribute('crossorigin', '*');
        s0.parentNode.insertBefore(s1, s0);
    })
();

Tawk_API.onLoad = function() {
    openChatIfActive();
};

Tawk_API.onChatStarted = function() {
    gtmPushEvent('chat_conversation_started');
};

Tawk_API.onStatusChange = function(status) {
    if (status === 'online') {
        window.setTimeout(showStatusonline, 10000);
    } else if (status === 'away') {
        $('button#toggleChatButton').addClass('hidden');
    } else if (status === 'offline') {
        $('button#toggleChatButton').addClass('hidden');
    }
};

$(document).on('click','#toggleChatButton',function(e) {
    toggleChat();
    gtmPushEvent('chat_widget_open');
    e.preventDefault();
    return false;
});