function enableModalScroll() {
	if(md.mobile()){
		$('body').css('overflow','hidden');
		$('body').css('position','fixed');
	}
};

function disableModalScroll() {
	if(md.mobile()){
		$('body').css('overflow','visible');
		$('body').css('position','static');
	}
};

$('div.modal button.close').on('click',function() {
	disableModalScroll();
});

function getForm(package_id) {
	$('div.chosen-packages').children('div').empty();
	$.ajax({
        url: "/ajax/wishlists/get",
        method: "GET",
        dataType: "json",
		success: function(response) {
			var wishlist = new Array();
			if(response.length == 0) {
				$('div.chosen-packages').addClass('hidden');
			} else {
				for(item in response) {
					wishlist.push(response[item].Package.id);
					$('div.chosen-packages').children('div').append('<input type="hidden" name="data[Package][Package][]" value="'+ response[item].Package.id +'"><p class="form-control-static"><a href="#" class="wishlist" data-package="'+ response[item].Package.id +'" data-action="remove"><i class="icon-cancel-circled"></i></a> ' + response[item].Package.title + '</p>');
					$('div.chosen-packages').removeClass('hidden');
				}
			}
			if(typeof package_id !== 'undefined') {
				if(wishlist.indexOf(package_id) == -1) {
					$.ajax({
						url: "/ajax/packages/get/" + package_id,
						method: "GET",
						dataType: "json",
						async: false,
						success: function(packageTitle) {
							$('div.chosen-packages').children('div').append('<input type="hidden" name="data[Package][Package][]" value="'+ package_id +'"><p class="form-control-static"><a href="#" class="wishlist" data-package="'+ package_id +'" data-action="remove"><i class="icon-cancel-circled"></i></a>' + packageTitle + '</p>');									
							$('div.chosen-packages').removeClass('hidden');
						}
					});
				}
			}
		}
    });
    enableModalScroll();
	$('#lead-full-form').modal('show');
	gtmPushEvent('form_opened');
}

// Load form
$('button[data-target="getForm"], a.getform').on('click', function() {
	getForm($(this).attr('data-package'));
	return false;
});
$(document).on('click','button[data-target="getForm"]',function() {
	getForm($(this).attr('data-package'));
	return false;
});
$('form#LeadAddForm .selectpicker').selectpicker({width : '100%'});

// Prefill form
$('#lead-full-form').on('shown.bs.modal', function (e) {
	var prefilled_fields = {
		'LeadPeriodStart': $('input#LeadPreselectionRangeStart').val(),
		'LeadPeriodEnd': $('input#LeadPreselectionRangeEnd').val(),
		'LeadPax': $('input#LeadPreselectionPax').val()
	};
	for(field in prefilled_fields) {
		$("#" + field).val(prefilled_fields[field]);
	}

	if(prefilled_fields.LeadPeriodStart != '' && typeof prefilled_fields.LeadPeriodStart !== "undefined" && typeof prefilled_fields.LeadPeriodEnd !== "undefined" && prefilled_fields.LeadPeriodEnd != '') {
		$('button[data-target="alternative-range"]').removeClass('hidden');
	}

	// Focus fist element
	if(!md.mobile()){
		$("#lead-full-form form").find('input.form-control, button').each(function() {
  			if(($(this).is('input') && $(this).val() == '') || ($(this).is('button') && ($(this).attr('title') == 'Bitte wählen' || $(this).attr('title') == 'Keine Präferenz'))) {
  				if($(this).is('input')) {
  					$(this).focus();
  				}
				return false;
  			}
  		});
	}
})

// Date Picker
var start_date = new Array();
var end_date = new Array();

$('div.travel-range input.form-control').each(function() {
	if($(this).parents('div.modal').length > 0) {
		var target_container = 'div.modal-content';

	} else {
		var target_container = 'body';
	}

	if($(this).data('range-type') == "start") {
		var orientation = 'bottom left';
	} else {
		var orientation = 'bottom right';
	}

	$(this).datepicker({
		format: 'dd.mm.yyyy',
		startDate: '+1d',
		language: 'de',
		weekStart: 1,
		maxViewMode: 2,
		container: target_container,
		orientation: orientation,
		disableTouchKeyboard: true,
		endDate: '+1y',
		autoclose: false,
		startView: $(this).data('start-view'),
		rangeGroup: $(this).data('range-group'),
		beforeShowDay: function(date) {
			var d = date;
     		var formattedTime = d.getTime();
     		var activeRangeGroup = 	$(this)[0]["rangeGroup"]
			if(typeof start_date[activeRangeGroup] != 'undefined' && start_date[activeRangeGroup] != null) {
				var start_time = start_date[activeRangeGroup].getTime();
				if(formattedTime == start_time) {
     				return "startdate";
     			}
     		}
			if(typeof end_date[activeRangeGroup] != 'undefined' && end_date[activeRangeGroup] != null) {
				var end_time = end_date[activeRangeGroup].getTime();
				if(formattedTime == end_time) {
     				return "enddate";
     			}
     		}

     		var selected_dates = new Array();
			selected_dates[activeRangeGroup] = getRange(activeRangeGroup);
			if(selected_dates[activeRangeGroup] != false) {
				if((formattedTime > selected_dates[activeRangeGroup][0]) && (formattedTime < selected_dates[activeRangeGroup][1])) {
					return "highlighted";
				}
			}
    	}
	});
});

function getRange(rangeGroup) {
	if(typeof start_date[rangeGroup] != 'undefined' && typeof end_date[rangeGroup] != 'undefined' && start_date[rangeGroup] != null && end_date[rangeGroup] != null) {
		start_range = start_date[rangeGroup].getTime();
		end_range = end_date[rangeGroup].getTime();
		var range = new Array(start_range, end_range);
		return range;
	} else {
		return false;
	}
}

$('span.date').on('click',function() {
	$(this).next('input.form-control').datepicker('show');
	$(this).next('input.form-control').focus();
});

// Anfangsdatum geändert
$('div.travel-range').find('input.form-control:eq(0)').on('changeDate', function(e) {
	var rangeGroup = $(this).data('range-group');
	start_date[rangeGroup] = $(this).datepicker('getDate');
	end_date_obj = $('input.form-control[data-range-group="'+ rangeGroup +'"]').last();

	// Kein End-Datum gesetzt
	if(end_date_obj.datepicker('getDate') == null) {
		end_date_obj.datepicker('update');
		end_date_obj.focus();
		$(this).datepicker('hide');
	} else {
		end_date[rangeGroup] = end_date_obj.datepicker('getDate');
		end_date_obj.datepicker('update');
		if(start_date[rangeGroup].getTime() >= end_date[rangeGroup].getTime()) {
			end_date_obj.datepicker('clearDates');
			end_date[rangeGroup] = null;
			end_date_obj.focus();
		} else {
			if(rangeGroup == 1) {
				$('button[data-target="alternative-range"]').removeClass('hidden');
			}
		}
		$(this).datepicker('hide');
	}
	var min_end_date = start_date[rangeGroup].getTime() + (1 * 24 * 60 * 60 * 1000); // +1d
	min_end_date = new Date(min_end_date);
	end_date_obj.datepicker('setStartDate', min_end_date);
	$(this).datepicker('update');
});

// Enddatum geändert
$('div.travel-range').find('input.form-control:eq(1)').on('changeDate', function(e) {
	var rangeGroup = $(this).data('range-group');
	end_date[rangeGroup] = $(this).datepicker('getDate');
	start_date_obj = $('input.form-control[data-range-group="'+ rangeGroup +'"]').first();
	start_date_obj.datepicker('update');
	// Kein Start-Datum gesetzt
	if(start_date_obj.datepicker('getDate') == null) {
		start_date_obj.focus();
	} else {
		if(rangeGroup == 1) {
			$('button[data-target="alternative-range"]').removeClass('hidden');
		}
	}
	$(this).datepicker('hide');

	// Genullt durch reset vom Startdatum (null)
	$(this).datepicker('update');
});

// Alternativer Zeitraum
$('button[data-target="alternative-range"]').on('click',function() {
	$(this).remove();
	$('div.alternative-range').removeClass('hidden');
	$('div.alternative-range').find('input.form-control:eq(0)').focus();
});

$('div.modal div.travel-range').find('input.form-control').on('focus', function(e) {
	$('div.modal').animate({
	 	scrollTop: ($(this).parents('div.travel-range').position().top)
	}, 'slow');
});

// Change travel type (on generic form without prefilled industry_id)
$('select[name="data[Lead][industry_id]"]').on('change', function() {
	if($(this).val() == '2') {
		$('select[name="data[Lead][category_id]"]').attr('disabled', 'disabled').parents('div.form-group').hide();
	} else {
		$('select[name="data[Lead][category_id]"]').removeAttr('disabled').parents('div.form-group').show();
	}
});