$(document).ready(function() {
    updateWishlist(updateButtonStatus);
});

function updateButtonStatus() {
    $('div.wishlist-items > div.row').each(function(index) {
        var update_package_id = $(this).attr('data-package-id');
        $('a[data-package="' + update_package_id + '"]').addClass('active');
        $('a[data-package="' + update_package_id + '"]').attr('data-action', 'remove');
    });
}

function updateWishlist(callback) {
    if ($('div#wishlist').hasClass('hidden')) {
        if ($('.btn-wishlist').hasClass('hidden')) {
            $(".btn-wishlist").removeClass('hidden').addClass('animated bounceInUp').one('animationend webkitAnimationEnd MSAnimationEnd oAnimationEnd', function(e) {
                $(this).removeClass('animated bounceInUp')
            });
        }
    }
    $.ajax({
        url: "/ajax/wishlists/get_details",
        method: "GET",
        dataType: "html",
        success: function(response) {
            $('div.wishlist-items').empty();
            $('div.wishlist-items').append(response);
            var countItems = $('div.wishlist-items > div.row').length;
            $(".btn-wishlist > span.badge").text(countItems);
            if (countItems == 0) {
                $(".btn-wishlist").addClass('hidden');
                $('div#wishlist').addClass('hidden');

            }
            $(".btn-wishlist").addClass('animated pulse').one('animationend webkitAnimationEnd MSAnimationEnd oAnimationEnd', function(e) {
                $(this).removeClass('animated pulse')
            });
            $('div.wishlist-items').scrollTop(0);
            if (typeof callback == 'function') {
                callback();
            }
        }
    });
}

function showWishlist() {
    $(".btn-wishlist").addClass('hidden');
    $("div#wishlist").removeClass('hidden').addClass('bounceInUp animated').one('animationend webkitAnimationEnd MSAnimationEnd oAnimationEnd', function(e) {
        $(this).removeClass('bounceInUp animated');
    });
}

function hideWishlist() {
    $("div#wishlist").addClass('bounceOutDown animated').one('animationend webkitAnimationEnd MSAnimationEnd oAnimationEnd', function(e) {
        $(this).addClass('hidden').removeClass('bounceOutDown animated');
        $(".btn-wishlist").removeClass('hidden');
    });
}

function toggleWishlist() {
    if ($(".btn-wishlist").hasClass('hidden')) {
        hideWishlist();
    } else {
        showWishlist();
    }
}

$(document).on('click', '.toggle-wishlist', function() {
    toggleWishlist();
    return false;
});

$(document).on('click', 'a.wishlist,button.wishlist', function() {
    var package_id = $(this).attr('data-package');
    var action = $(this).attr('data-action');
    if (action == 'add') {
        $('a[data-package="' + package_id + '"]').addClass('active');
        $(this).attr('data-action', 'remove');
        $.ajax({
            url: "/ajax/wishlists/add/" + package_id,
            method: "GET",
            dataType: "json",
            success: function(countItems) {
                gtmPushEvent('added_to_wishlist');
                updateWishlist();
                if (countItems == 1) {
                    showWishlist();
                } else {
                    $("div#wishlist").addClass('animated pulse').one('animationend webkitAnimationEnd MSAnimationEnd oAnimationEnd', function(e) {
                        $(this).removeClass('animated pulse')
                    });
                }
            }
        });
    }
    if (action == 'remove') {
        $('div.row[data-package-id="' + package_id + '"]').addClass('animated bounceOutRight').one('animationend webkitAnimationEnd MSAnimationEnd oAnimationEnd', function(e) {
            $(this).addClass('hidden').removeClass('bounceOutRight animated');
        });
        $('a[data-package="' + package_id + '"]').removeClass('active');

        if($(this).parents('.chosen-packages').length > 0) {
            $(this).parent('p').remove();
            $('div.chosen-packages').find('input[value="' + package_id + '"]').remove();
        } else {
            $(this).attr('data-action', 'add');
        }

        $.ajax({
            url: "/ajax/wishlists/remove/" + package_id,
            method: "GET",
            dataType: "json",
            complete: function(remove_response) {
                updateWishlist();
                $(this).parent('p.form-control-static').remove();
                if ($('div.chosen-packages').children('div').find('p').length == 0) {
                    $('div.chosen-packages').addClass('hidden');
                }
            }
        });

    }
    return false;
});