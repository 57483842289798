function pushLeadDataIntoGtm(response) {
    if(typeof response.campaign_id !== "undefined") {
        gtmPushVariable('leadCampaignId',response.campaign_id);
    }
    gtmPushVariable('leadId',response.lead_id);
}

/* create a lead */
$('form#LeadAddForm').on('submit',function(e) {
    gtmPushEvent('form_interaction_submit');
    if(typeof ga_cid !== "undefined" && ga_cid) {
        if(ga_cid !== '') {
            $('[name="data[Click][ga_cid]"]').val(ga_cid);
        }
    }
	$('div.modal-body').css('opacity','0.15');
	$('div.modal-body').before('<div class="spinner"><i class="icon-circle-notch animate-spin icon-5x icon-primary"></i></div>');
	$('.has-error').removeClass('has-error');
	$('div.alert-danger').remove();
	var form_data = $('form#LeadAddForm').serialize();
	$.ajax({
    	url: "/ajax/leads/add",
    	data: form_data,
    	type: "POST",
    	dataType: "json",
    	success: function (success) {
        	$(".spinner").remove();
        	$('div.modal-body').css('opacity','1.0');
            if (typeof success.errors !== "undefined" && success.errors) {
    			gtmPushEvent('form_validation_error');
                $("div.modal-body").prepend('<div class="alert alert-danger" role="alert">Bitte korrigiere Deine Angaben in den rot markierten Feldern.</div>');
                $.each(success.errors, function (key, value) {
            		$.each(value, function (subModelIndex, subModelValue) {
                		$('input[name="data[' + key + '][' + subModelIndex + ']"]').parents('div.form-group').addClass('has-error');
                		$('select[name="data[' + key + '][' + subModelIndex + ']"]').parents('div.form-group').addClass('has-error');
                	});
            	});
            	$('div.modal').animate({
    				scrollTop: ($("div.alert-danger").offset().top)
				}, 'slow');
            } else {
				pushLeadDataIntoGtm(success);
    			gtmPushEvent('lead_created');
				$("h4.modal-title").text('Vielen Dank für Eure Anfrage!');
				$("div.modal-body").prepend('<p>Wir werden Euch schnellstmöglich passende Angebote für Eure Gruppe zusammenstellen.</p><p>Habt Ihr noch <strong>spezielle Wünsche</strong>, die wir dabei berücksichtigen sollten? Dann teilt uns dies gerne über das nachfolgende Kommentarfeld mit.</p><hr class="invisible">');
				$("form#LeadAddForm").addClass('hidden');
				$("form#LeadEditForm").removeClass('hidden');
				$("input#LeadId").val(success.lead_id);
				if (typeof ga == 'function') {
					ga('send', 'event', 'form', 'submit', 'match', '0');
				}
            }
    	}
	});
	e.preventDefault();
	return false;
});

$('.form-control, select.selectpicker').on('change',function() {
	$(this).parents('div.form-group').removeClass('has-error');
});

/* add data to existing lead */
$(document).on('submit','form#LeadEditForm',function(e) {
	$('form#LeadEditForm').css('opacity','0.15');
	$('form#LeadEditForm').before('<div class="spinner"><i class="icon-circle-notch animate-spin icon-5x icon-primary"></i></div>');
	var form_data = $('form#LeadEditForm').serialize();
	$.ajax({
    	url: "/ajax/leads/edit",
    	data: form_data,
    	type: "POST",
    	dataType: "json",
    	success: function (success) {
        	$(".spinner").remove();
			$('form#LeadEditForm').replaceWith('<p><i class="icon-check icon-primary"></i> Bemerkung erfolgreich versendet.');
    	}
	});
    gtmPushEvent('lead_data_added');
	e.preventDefault();
	return false;
});