function gtmPushEvent(event_title) {
    if(typeof dataLayer !== "undefined") {
        dataLayer.push({'event': event_title});
    }
}

function gtmPushVariable(variable_name,value) {
    if(typeof dataLayer !== "undefined") {
        var objs = {};
        objs[variable_name] = value;
        dataLayer.push(objs);
    }
}

var md = '';

$(document).ready(function() {
	md = new MobileDetect(window.navigator.userAgent);

	if(md.mobile()){
	    $('.whatsapp').removeClass('hidden');
	};
});